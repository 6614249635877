<template>
<v-container>
  <!-- BOC:[breadcrumbs] -->
  <ABreadcrumb :items="breadcrumbs"></ABreadcrumb>
  <!-- EOC -->
  <!-- BOC:[form] -->
  <BreadForm
    :role="role"
    :model="model"
    :url="`${$api.servers.moderator}/v1/school/add`"
    action="add"
    :callbackSuccess="callbackSuccess"
  ></BreadForm>
  <!-- EOC -->
</v-container>
</template>

<script>
  //BOC:[model]
  import model from '@/models/items/school'
  //EOC
  import BreadForm from '@/components/Bread/BreadForm'
  import { mapState } from 'vuex'
  export default {
    components:{
      BreadForm,
    },
    computed: mapState({
      //
    }),
    props:[
      //
    ],
    data: () => ({
      //BOC:[breadcrumbs]
      breadcrumbs: [],
      //EOC
      //BOC:[model]
      model: model,
      //EOC
      //BOC:[role]
      role:'Moderator',
      //EOC
    }),
    created() {
      //BOC:[breadcrumbs]
      this.breadcrumbs.push({
        text:this.$t("model.name.dashboard"),
        to:{name:'Page'+this.role+'Dashboard'},
        exact:true,
      })
      //
      this.breadcrumbs.push({
        text:this.$t("model.name." + this.model.name.plural.toLowerCase()),
        to:this.$_getRouteBrowse(this.role,this.model.key),
        exact:true,
      })
      //
      this.breadcrumbs.push({
        text:`${this.$t("string.bread_new")}${this.$t("model.name." + this.model.name.singular.toLowerCase())}`,
        to:this.$_getRouteAdd(this.role,this.model.key),
        exact:true,
      })
      //EOC
    },
    mounted() {
      //
    },
    methods: {
      callbackSuccess(resp) {
        this.$store.commit("updateSchool",resp)
        this.$router.push({name:'Page'+this.role+'Dashboard'})
      },
    }
  }
</script>